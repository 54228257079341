@import '../../styles/Globals';


    h2 {
        color: white;
        font-size: 48px;
        font-family: $futura;
        text-transform: uppercase;
    }
    .track-container {
        height: 150px;
        align-items: center;
        padding-right:20px;
        .track-info {
            display: flex;
            color: #fff;
            font-family: $futura;
            font-weight: 300;
            cursor:pointer;
            p:nth-of-type(1) {
                font-weight: 700;
            }
            .track-text {
                display: flex;
                height: 50%;
                flex-direction: column;
                justify-content: space-around;
                margin: auto 0;
                margin-left: 40px;
                p{
                    transition:all 0.25s ease-in;
                }
            }
            &:hover{
                p{
                    color:$purple
                }
            }
        }
        width: 100%;
        border-bottom: 2px solid rgba(107, 107, 107, 0.1);
        display: flex;
        justify-content: space-between;
        img {
            width: 100px;
            height: 100px;
        }
        .search, .like {
            background: none;
            width: 80px;
            height: 80px;
            border-radius: 100%;
            color: #fff;
            border: 1px solid #fff;
            margin: 0;
            display:flex;
            align-items:center;
            justify-content:center;
            cursor:pointer;
            transition-property: background-color, fill;
            transition: 0.25s ease-in;
            cursor:pointer;
            &:hover{
                border-color:$purple;
                svg{
                    fill:$purple;
                }
            }
            svg {
                width: 24px;
                height: 24px;
                transition: 0.25s ease-in;
            }
        }
        .track-input-wrapper{
            width:20%;
            min-width:170px;
        }
    }