@import '../../styles/_Globals.scss';

    .title-container{
        margin-right:200px;
        padding-bottom:100px;
        padding-top:100px;
        color: #fff;
        .title{
            display:flex;
            text-align:right;
            flex-direction:column;
            align-items:flex-end;
            gap:1px;
            h1{
                font-size:160px;
                font-family:$futura;
                text-transform:uppercase;
                font-weight:700;
                span{
                    display:block;
                    line-height:0.9;
                }
            }
        }
        h4{
            font-family:$futura;
            font-weight:normal;
            font-size:20px;
            letter-spacing: 2px;
            margin-top:0.5rem;
        }

    }