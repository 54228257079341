@import '../../styles/Globals';

.track-controls {
	position: fixed;
	width: 100%;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	.PlayerRSWP {
		p {
			color: #fff;
			font-family: $futura;
			font-weight: 300;
			padding-top: 10px;
		}
	}
	._InfoRSWP {
		border: none !important;
		position: absolute;
		bottom: 0px;
        left:20px;
		transform: translateY(-50%);
        max-width: 45%;
	}
	.rswp__toggle {
		border: solid 1px #fff;
		border-radius: 100%;
		width: 70px;
		height: 70px;
		cursor: pointer;
		transition: 0.2s ease-in;
        margin-bottom:10px;
		path {
			transition: 0.2s ease-in;
		}
		&:hover {
			border-color: $purple;
			svg path {
				fill: $purple;
			}
		}
	}
    .__f73p8j{
        button{
            svg{
                margin-top:5px;
            }
        }
    }
	._ControlsRSWP {
		height: auto;
		margin-top: 20px;
		button {
			svg {
				width: 15px;
			}
		}
	}
	button {
		height: 26px;
		width: 26px;
	}
	.track-control {
		background: none;
		margin: 0;
	}
	.play {
		width: 182px;
		height: 182px;
		svg {
			width: 60px;
			height: 60px;
			fill: #fff;
			transition: 0.2s ease-in;
		}
	}
	.rewind,
	.forward {
		svg {
			width: 40px;
			height: 40px;
			fill: #fff;
			transition: 0.2s ease-in;
		}
	}
}
