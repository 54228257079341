// COLOR

$black: #101010;
$purple: #9351BB;

//GRADIENTS
$background: linear-gradient(180deg, $black 70%, $purple 200%);

//FONTS
@font-face {
    font-family: 'Futura';
    src: local('Futura Bold'), local('Futura-Bold'),
        url('../assets/fonts/FuturaLT-Bold.woff2') format('woff2'),
        url('../assets/fonts/FuturaLT-Bold.woff') format('woff'),
        url('../assets/fonts/FuturaLT-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Futura';
    src: local('Futura Book'), local('Futura-Book'),
        url('../assets/fonts/FuturaLT-Book.woff2') format('woff2'),
        url('../assets/fonts/FuturaLT-Book.woff') format('woff'),
        url('../assets/fonts/FuturaLT-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Futura';
    src: local('Futura Light'), local('Futura-Light'),
        url('../assets/fonts/FuturaLT-Light.woff2') format('woff2'),
        url('../assets/fonts/FuturaLT-Light.woff') format('woff'),
        url('../assets/fonts/FuturaLT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

$futura: 'Futura', 'sans-serif';

//STYLES
*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}

.background{
    width:100%;
    min-height:100vh;
    background: $background;
}

.flex{
  display:flex;
}

.flex-center{
  display:flex;
  width:100%;
  align-items:center;
  justify-content:center;
}

.flex-between{
  display:flex;
  align-items:center;
  justify-content:space-between;
}