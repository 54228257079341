@use 'Globals';

.no-mobile{
    display:none;
}

@media only screen and (max-width: 728px) {
    .no-mobile{
        position:fixed;
        top:0;
        left:0;
        z-index:10;
        background-color:#101010;
        width:100%;
        height:100vh;
        h1{
            text-align:center;
            position:absolute;
            top:50%;
            left:50%;
            font-family:'Futura', 'sans-serif';
            color:white;
            font-weight:700;
            width:50%;
            transform:translate(-50%,-50%);
        }
    }
}