@import '../../styles/Globals';

.exit-button{
    height:42px;
    width:111px;
    background-color:$purple;
    margin:0;
    border:none;
    position:relative;
    cursor:pointer;
    .text-wrapper{
        display:flex;
        position:absolute;
        top:50%;
        transform:translate(50%,-50%);
        right:0;
        p{
            color:#fff;
            font-size:16px;
            font-family:$futura;
            font-weight:300;
        }
        img{
            width:20px;
            margin-left:10px;
        }
    }
}
    .text-wrapper{
        display:flex;
        transform:translate(75%,-50%);
        p{
            font-size:20px;
            letter-spacing:3px;
            margin:0;
        }
    }
    
.search-button{
    margin:auto;
    display:block;
    width:170px;
    height:60px;
    .text-wrapper{
        right:0;
    }
}