@import '../../styles/Globals';


.track-content-container {
    width: 70%;
    margin: auto;
    h2{
        font-weight:700;
    }
    .tracks-wrapper{
        overflow-y: auto;
        height:250px;
        margin-top:10px;
        scrollbar-color:$purple rgba(255,255,255,0);
    }
    .form-control{
        width:40%;
        margin-top:10px;
        background:none;
        border:none;
        border-bottom:1px solid #fff;
        border-radius:0;
        position:sticky;
        color:white;
        font-family:$futura;
        font-weight:300;
        padding:10px;
        &:focus{
            background:none;
            border:none;
            border-bottom:1px solid #fff;
            box-shadow:none;
            caret-color:white;
            color:white;
        }
        &::placeholder{
            color:rgba(255,255,255,0.6);
        }
    }
}