@import '../../styles/Globals';

.song-container {
	.track-content {
		width: 60%;
		margin: auto;
		.track-info {
			display: flex;
			gap: 40px;
			align-items: center;
			.cover-art {
				width: 200px;
				margin-left: 100px;
			}
			.track-text {
				display: flex;
				flex-direction: column;
				h4 {
					font-size: 64px;
					font-weight: 700;
					max-width: 400px;
					word-wrap: break-word;
				}
				p {
					font-size: 30px;
					font-weight: 300;
				}
			}
		}
		.presets-wrapper{
			margin:auto;
			margin-top:40px;
			width:100%;
			display:flex;
			justify-content:space-around;
			button{
				background-color:$purple;
				border:none;
				min-width:170px;
				min-height:60px;
				color:white;
				font-family: $futura, sans-serif;
				transition:all 0.25s ease-in-out;
				&:hover{
					transform:translateY(-10px)
				}
			}
		}
		h4,
		p,
		h2,
		h3 {
			color: white;
			font-family: $futura;
		}
		.slider-container {
			.slider {
				.slider-data {
					display: flex;
					align-items: center;
					width: 100%;
					justify-content: space-between;
					h3 {
						font-size: 36px;
						font-weight: 700;
						margin-left: 30px;
					}
				}
				p {
					margin: 0;
				}
			}
			margin-top: 40px;
		}
	}

    .no-results {
        text-align: center;
        font-weight: 700;
        padding-bottom: 150px;
		margin:0;
    }
}
