@import '../../styles/Globals';

.search-button{
    height:80px;
    width:280px;
    background-color:$purple;
    border:none;
    position:relative;
    margin:100px auto 100px auto;
    cursor:pointer;
    .text-wrapper{
        display:flex;
        position:absolute;
        top:50%;
        transform:translate(50%,-50%);
        p{
            color:#fff;
            font-size:24px;
            font-family:$futura;
            font-weight:300;
        }
    }
}
.header-button{
    width:111px;
    height:42px;
    margin:0;
    transform-origin:0% 0%;
    .text-wrapper{
        display:flex;
        transform:translate(75%,-50%);
        p{
            font-size:20px;
            letter-spacing:3px;
        }
    }
}
.search-button{
    display:block;
    width:170px;
    height:60px;
    .text-wrapper{
        right:0;
    }
}